import {
  Avatar,
  CircularProgress,
  DialogContent,
  Grid,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box, Stack } from '@mui/system';
import '@splidejs/react-splide/css';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../store';
import { FaRegComment, FaRegHeart } from 'react-icons/fa';
import IosShareIcon from '@mui/icons-material/IosShare';
import useToggle from '../../hooks/useToggle';
import SocialDailog from './SocialDailog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactReadMoreReadLess from '../ReactReadMoreReadLess';
import { addCompanyFeedShare, createUpdateUserCompanyFeedReaction } from '../../store/social/api';
import { socialFeedUpdated } from '../../store/social/socialSlice';
import { SOCIAL_LIKE_ICONS } from '../../constant';
import CompanyFeedCommentsModal from '../modal/CompanyFeedCommentsModal';
import { CompanyFeed } from '../../store/social/api/interface';
import { setCommunityFeed } from '../../store/communityFeed/communityFeedSlice';
import { SharePostType, SocialShareType } from '../../enum';
import CompanyFeedShareLinkModal from '../modal/CompanyFeedShareLinkModal';
import Iconify from '../Iconify';
import { AppColors, config } from '../../utils/utils';
import { checkIsProUser } from '../../utils/checkPro';
import { encryptString } from '../../utils/url';
import { shareSocialFeedMessage } from "../../store/social/socialSlice"
import { openLinkOnNewTab, shareSocialPostMessage, showSuccessMessage } from '../../utils/utilMethods';
import Vimeo from '@u-wave/react-vimeo';
import EyeIcon from '../../assets/eye.svg';
import CommentIcon from '../../assets/comment.svg';
import HeartIcon from '../../assets/heart.svg';
import ShareIcon from '../../assets/share.svg';

type IProps = {
  toggle: boolean;
  onClose: () => void;
  redirectToContact: boolean;
};

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const ViewCommunityModal: React.FC<IProps> = ({
  toggle,
  onClose,
  redirectToContact,
}) => {
  const {
    toggle: toggleSocialDailog,
    onOpen: onOpenSocialDailog,
    onClose: onCloseSocialDailog,
  } = useToggle();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const name = useAppSelector((state) => state?.userInfo?.data?.name ?? state?.auth?.user?.name);
  const loginUserInfo = useAppSelector((state) => state?.auth?.user);
  const occupation = useAppSelector(
    (state) => state?.userInfo?.data?.occupation ?? state?.auth?.user?.occupation
  );

  const profile_picture = useAppSelector(
    (state) => state?.userInfo?.data?.profile_picture ?? state?.auth?.user?.profile_picture
  );
  const userName = useAppSelector((state) => state?.userInfo?.data?.username ?? state?.auth?.user?.username);

  const { data: community_feed, isLoading } = useAppSelector(
    (state) => state?.communityFeed
  );

  const { data: userInfo, sbUser: sbUserInfo } = useAppSelector((state) => state.userInfo);

  const referenceUserAffiliateLink = useAppSelector(
    (state) => state.userInfo.sbAffiliateLink
  );


  const {
    toggle: toggleFeedComment,
    onOpen: onOpenFeedComment,
    onClose: onCloseFeedComment,
  } = useToggle();

  const {
    toggle: toggleFeedLinkShare,
    onOpen: onOpenFeedLinkShare,
    onClose: onCloseFeedLinkShare,
  } = useToggle();

  const openFeedCommentDailog = () => {
    onOpenFeedComment();
  };

  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [totalSlide, setTotalSlide] = useState<number>(0)

  useEffect(() => {
    if (toggle) {
      setActiveSlide(0);
    }
  }, [toggle]);

  const [likeAnchorEl, setLikeAnchorEl] = useState<null | HTMLElement>(null);

  const openLikeMenu = Boolean(likeAnchorEl);
  const handleLikeMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setLikeAnchorEl(event.currentTarget);
  };
  const handleLikeMenuClose = async (likeCompanyFeed?: CompanyFeed, reaction_id?: number) => {
    setLikeAnchorEl(null);
    if (likeCompanyFeed) {
      let result = await dispatch(createUpdateUserCompanyFeedReaction({ companyFeedId: likeCompanyFeed.id, reactionId: reaction_id })).unwrap();

      if (result.data.status === 'success') {
        let updatedLikeCompanyFeed = { ...likeCompanyFeed };
        if (result.data.data !== undefined) {
          updatedLikeCompanyFeed.reaction = result.data.data;
          updatedLikeCompanyFeed.reactions_count += 1;
        } else {
          updatedLikeCompanyFeed.reaction = undefined;
          if (updatedLikeCompanyFeed.reactions_count > 0) {
            updatedLikeCompanyFeed.reactions_count -= 1;
          }
        }
        dispatch(socialFeedUpdated(updatedLikeCompanyFeed));
        dispatch(setCommunityFeed(updatedLikeCompanyFeed));
      }
    }
  };


  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const openShareMenu = Boolean(shareAnchorEl);
  const handleShareMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareMenuClose = async (shareCompanyFeed?: CompanyFeed, socialShareType?: string) => {
    setShareAnchorEl(null);

    if (shareCompanyFeed != null) {
      switch (socialShareType) {
        case SocialShareType.link:
          onOpenFeedLinkShare();
          dispatch(addCompanyFeedShare({
            postById: shareCompanyFeed?.company.owner?.id ?? 0,
            companyFeedId: shareCompanyFeed.id,
            actionType: socialShareType,
            targetId: undefined
          })).unwrap().then((result) => {
            if (result.status == 200 && result.data.status == "success") {
              if (shareCompanyFeed.id == result.data.data.company_feed_id) {
                let updatedShareCompanyFeed = { ...shareCompanyFeed, shares_count: (shareCompanyFeed?.shares_count || 0) + 1 }
                dispatch(socialFeedUpdated(updatedShareCompanyFeed));
                dispatch(setCommunityFeed(updatedShareCompanyFeed));
              }
            }
          });
          break;
        case SocialShareType.community:

          dispatch(shareSocialFeedMessage({ socialPost: shareCompanyFeed, message: shareSocialPostMessage(userName ?? "", shareCompanyFeed, loginUserInfo) }));
          navigate(`/dashboard/messaging?${config.SHARE_KEY}=${encryptString(SharePostType.social_post)}`);
          break;
        case SocialShareType.share_faceook:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.FACEBOOK_URL);
          break;
        case SocialShareType.share_instagram:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.INSTAGRAM_URL);
          break;
        case SocialShareType.share_linkedin:
          navigator.clipboard.writeText(shareCompanyFeed?.description ?? "");
          showSuccessMessage("Copied the social post.");
          openLinkOnNewTab(config.LINKEDIN_URL);
          break;

      }
    }
  };


  // const ref = useRef<any>(null);

  // const isOverflow = useIsOverflow(ref, (isOverflowFromCallback: any) => {
  //   console.log('isOverflowFromCallback => ', isOverflowFromCallback);
  //   setShowMore(isOverflowFromCallback);
  // });

  const ref = useCallback((node: any) => {
    if (node !== null) {
      setShowMore(node.scrollHeight > node.getBoundingClientRect().height);

      const measure = () => {
        if (node !== null) {
          setShowMore(node.scrollHeight > node.getBoundingClientRect().height);
        }
      };
      window.addEventListener('resize', measure);
      return () => {
        window.removeEventListener('resize', measure);
      };
    }
  }, []);


  const handleCloseDialog = () => {

    onClose();
    // let sc = searchParams.get('sc');
    // let sb = searchParams.get('sb');
    // let elid = searchParams.get('elid');
    // let quertParams = "";
    // if (sc) {
    //   quertParams = `?sc=${sc}`
    //   if (elid) {
    //     quertParams += `&elid=${elid}`
    //   }
    // }

    // if (sb) {
    //   quertParams += `${quertParams.length > 0 ? "&" : "?"}sb=${sb}`
    // }
  };

  const openShareByKnoCard = () => {
    if (sbUserInfo?.username) {
      let userVbcLink = `${config.VBC_URL}/${sbUserInfo?.username ?? ''}`;
      window.open(userVbcLink) || window.location.replace(userVbcLink);
    }
  };

  const bottomRef = useRef<null | HTMLDivElement>(null);
  const hasMedia =
    community_feed?.company_feed?.contents != undefined &&
    community_feed?.company_feed?.contents?.length > 0;

    const handleGetStartedCompanyLink = ()=>{
      if(userInfo?.buy_now_link ){
        openLinkOnNewTab(userInfo?.buy_now_link ||"");
      }
    };

  const getLikeCommentShareView = () => {
    return (<Box
      display='flex'
      mt={2}
      sx={{ width: "100%" }}
    >
      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
        sx={{ mr: 3 }}
      >
        {loginUserInfo ? <><Box
          aria-controls={openLikeMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLikeMenu ? 'true' : undefined}
          onClick={(e) => community_feed?.company_feed?.reaction ? handleLikeMenuClose(community_feed?.company_feed, undefined) : handleLikeMenuClick(e)}
        >
          {(community_feed?.company_feed?.reaction && community_feed?.company_feed?.reaction.reaction_id >= 0 && community_feed?.company_feed.reaction.reaction_id <= 5) ? <Box component={'img'} src={SOCIAL_LIKE_ICONS[community_feed?.company_feed?.reaction.reaction_id]} sx={{
            height: '1.5rem',

          }}></Box> : <Box
            component={'img'}
            src={HeartIcon}
            style={{
              height: '1.5rem',
              width: '1.5rem',
            }}
          />}</Box>
          <Menu
            anchorEl={likeAnchorEl}
            open={openLikeMenu}
            onClose={() => handleLikeMenuClose()}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}

          >
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 0)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[0]} sx={{
              height: '1.5rem',

            }}></Box></MenuItem>
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 1)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[1]} sx={{
              height: '1.5rem',

            }}></Box></MenuItem>
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 2)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[2]} sx={{
              height: '1.5rem',

            }}></Box></MenuItem>
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 3)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[3]} sx={{
              height: '1.5rem',

            }}></Box></MenuItem>
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 4)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[4]} sx={{

              height: '1.5rem',
            }}></Box></MenuItem>
            <MenuItem onClick={() => handleLikeMenuClose(community_feed?.company_feed, 5)}><Box component={'img'} src={SOCIAL_LIKE_ICONS[5]} sx={{
              height: '1.5rem',
            }}></Box></MenuItem>
          </Menu>
        </> : <Box
          component={'img'}
          src={HeartIcon}
          sx={{
            height: '1.5rem',
            width: '1.5rem'
          }}
          onClick={() => onOpenSocialDailog()}
          style={{
            height: '1.5rem',
            width: '1.5rem',
            color: AppColors.primaryColor,
          }}
        />}

        <Typography
          variant='body2'
          component='div'
          sx={{ color: AppColors.grayTwoColor }}
        >
          {community_feed?.company_feed?.reactions_count ?? 0}
        </Typography>
      </Stack>

      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
        sx={{ mr: 3 }}
      >
        <Box
          component={'img'}
          src={CommentIcon}
          onClick={() => loginUserInfo ? openFeedCommentDailog() : onOpenSocialDailog()}
          sx={{
            height: '1.5rem',
            width: '1.5rem',
          }}
        />

        <Typography
          variant='body2'
          component='div'
          sx={{ color: AppColors.grayTwoColor }}
        >
          {community_feed?.company_feed?.comments_count ?? 0}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
        sx={{ mr: 3 }}
      >
        <Box
          aria-controls={openLikeMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLikeMenu ? 'true' : undefined}
          onClick={(e) => loginUserInfo ? handleShareMenuClick(e) : onOpenSocialDailog()}
        >
          <Box
            component={'img'}
            src={ShareIcon}
            sx={{
              height: '1.5rem',
              width: '1.5rem',
            }}
          /></Box>
        <Menu
          anchorEl={shareAnchorEl}
          open={openShareMenu}
          onClose={() => handleShareMenuClose()}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        >
          <MenuItem onClick={() => handleShareMenuClose(community_feed?.company_feed, SocialShareType.link)}>Share Link</MenuItem>
          {checkIsProUser(loginUserInfo) && (<MenuItem onClick={() => handleShareMenuClose(community_feed?.company_feed, SocialShareType.community)}>Share with Community</MenuItem>)}
          <MenuItem onClick={() => handleShareMenuClose(community_feed?.company_feed, SocialShareType.share_faceook)}>Copy to Facebook</MenuItem>
          <MenuItem onClick={() => handleShareMenuClose(community_feed?.company_feed, SocialShareType.share_instagram)}>Copy to Instagram</MenuItem>
          <MenuItem onClick={() => handleShareMenuClose(community_feed?.company_feed, SocialShareType.share_linkedin)}>Copy to LinkedIn</MenuItem>
        </Menu>
        <Typography
          variant='body2'
          component='div'
          sx={{ color: AppColors.grayTwoColor }}
        >
          {community_feed?.company_feed?.shares_count ?? 0}
        </Typography>
      </Stack>
      <Stack
        direction='row'
        color='grey'
        alignItems='end'
        justifyContent={'flex-end'}
        gap={1}

        sx={{ width: '100%' }}
      >

        <Box
          component={'img'}
          src={EyeIcon}
          style={{
            height: '1.5rem',
            width: '1.5rem'
          }}
        />

        <Typography
          variant='body2'
          component='div'
          sx={{ color: AppColors.grayTwoColor }}
        >
          {community_feed?.company_feed?.views_count ?? 0}
        </Typography>
      </Stack>
    </Box>);
  };

  const getTakeTheFastTrack = () => {
    return (<Box
      alignItems='center'
      justifyContent='space-between'
      display={'flex'}
      sx={{ width: "100%",border:`solid 1.5px ${AppColors.grayOneColor}`, borderRadius:"10px",pl:1, pr:1,pt:0.5,pb:0.5 }}
    >
      <Stack
        direction='row'
        color='grey'
        alignItems='center'
        gap={1}
      >
        <Typography textAlign="center" sx={{ color: AppColors.grayTwoColor, fontSize: {md:'1.0rem',xs:'0.8rem'},  fontWeight:"500" }}>Take the</Typography>
        <Typography textAlign="center" sx={{ color: AppColors.greenColor, fontSize: {md:'1.0rem',xs:'0.8rem'}, fontWeight:"500", borderBottom: "6px solid transparent",borderImage: "url('/static/images/black_line.png') 42 stretch" }}>FAST TRACK</Typography>
      </Stack>
      <Stack flexDirection={'row'} sx={{ backgroundColor: AppColors.greenColor, border: `1.5px solid ${AppColors.strokeColor}`, pl: {md:2,xs:0.5}, pr: {md:2,xs:0.5}, pt: 1, pb: 1,  borderRadius: "50px",width:"fit-content" }} onClick={handleGetStartedCompanyLink}>
          <Typography textAlign="center" sx={{ color: AppColors.whiteColor,fontSize: {md:'1.0rem',xs:'0.8rem'},ml: 2, mr: 2 }}>Get Started</Typography>
      </Stack>


    </Box>);
  };

  return (
    <>
      <Dialog
        open={toggle}
        onClose={() => {
          onClose();
          let sc = searchParams.get('sc');
          let sb = searchParams.get('sb');
          let elid = searchParams.get('elid');
          let quertParams = "";
          if (sc) {
            quertParams = `?sc=${sc}`
            if (elid) {
              quertParams += `&elid=${elid}`
            }
          }

          if (sb) {
            quertParams += `${quertParams.length > 0 ? "&" : "?"}sb=${sb}`
          }


          if (redirectToContact) navigate(`/${userInfo?.username}/contact${quertParams}`);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        fullWidth
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        {!sbUserInfo && <Button
          variant='contained'
          sx={{
            backgroundColor: AppColors.primaryColor,
            textTransform: 'none',
            fontWeight: '400',
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 1000,
            lineHeight: 'normal',
            py:0.3
          }}
          onClick={() => handleCloseDialog}
        >
          View KnoCard
        </Button>}
        {/*<IconButton
          aria-label='close'
          onClick={onClose}
          sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
        >
          <CloseIcon />
        </IconButton>*/}
        <DialogContent sx={{ p: 1 }}>
          {isLoading ? (
            <Box width='100%' display='flex' justifyContent='center'>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: { xs: 1, md: 0 } }}
              >
                {hasMedia && (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    alignSelf={'center'}
                    sx={{ display: { xs: 'none', md: 'block' }, position: 'relative' }}
                  >
                    <Splide
                      aria-label='Images'
                      options={{
                        perPage: 1,
                        fixedHeight: 425,
                        autoHeight: true
                      }}
                      onMove={(event) => {
                        setActiveSlide(event.index);
                      }}
                      onReady={(event) => {
                        setTotalSlide(event.length)
                      }}
                      style={{ borderRadius: "12px", overflow: 'hidden' }}
                    >
                      {community_feed?.company_feed?.contents?.map(
                        (content) => {
                          return content?.type === 'image' ? (
                            <SplideSlide key={content.id}>
                              <img
                                src={content?.link}
                                alt={content?.filename ?? ''}
                                height='100%'
                                width='100%'
                                className='object-fit-contain'
                              />
                            </SplideSlide>
                          ) : (
                            <SplideSlide key={content.id}>
                              {content?.platform === 'youtube' ? (
                                <YouTube
                                  videoId={content?.link}
                                  opts={opts}
                                  className='object-fit-contain'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                  }}
                                // onPlay={() => handleOnplayVideo(content.id)}
                                />
                              ) : content?.platform === "vimeo" ? (
                                <Vimeo
                                  video={content?.link}
                                  className="object-fit-contain vimeo-video-contain"
                                  height={"100%"}
                                  width={"100%"}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    maxHeight: '50vh'
                                  }}
                                // onPlay={() => handleOnplayVideo(content.id)}
                                />
                              ) : (
                                <video
                                  controls
                                  width='100%'
                                  height='100%'
                                  className='object-fit-contain'
                                  poster={content?.thumbnail ?? ''}
                                //onPlay={() => handleOnplayVideo(content.id)}
                                >
                                  <source
                                    src={content?.link}
                                    type='video/mp4'
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </SplideSlide>
                          );
                        }
                      )}
                    </Splide>
                    {community_feed?.company_feed?.contents && community_feed?.company_feed?.contents?.length > 1 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse', position: 'absolute', top: 20, right: 8 }}>
                      <Button
                        variant='contained'
                        sx={{
                          backgroundColor: AppColors.primaryColor,
                          textTransform: 'none',
                          fontSize: "0.9rem",
                          zIndex: 1000,
                          lineHeight: 1,
                          fontWeight: "400",
                          py:0.5 
                        }}
                      >

                        {`${activeSlide + 1}`} of {totalSlide ?? 0}
                      </Button>
                    </Box>}
                  </Grid>
                )}

                <Grid item xs={12} md={hasMedia ? 6 : 12}>
                  <Stack direction='column' width='100%'>
                    {sbUserInfo && <Box
                      display='flex'
                      alignItems='center'
                      minHeight='60px'
                      sx={{
                        backgroundImage: 'url("/static/images/header_bg.svg")',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        m: -1,
                        pl: 1,
                        pr: 1,
                        pt: 0.5,
                        pb: 0.5
                      }}
                    >
                      <Box sx={{ borderRadius: "100%" }}>
                        <Avatar alt='pic' src={sbUserInfo.profile_picture ?? '/static/images/placeholder_profile.png'} />
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'
                        flex={1}
                      >
                        <Typography
                          variant='h6'
                          component='div'
                          color='white'
                          sx={{ ml: 1, mr: 1, lineHeight: 1.2, fontSize: { xs: '1rem', md: "1.2rem" } }}
                        >
                          {sbUserInfo?.first_name ?? ""}'s KnoCard
                        </Typography>
                      </Box>
                      <Box
                        flexDirection='row'
                      >
                        <Button
                          onClick={openShareByKnoCard}
                          variant="text"
                          sx={{
                            color: AppColors.whiteColor,
                            textTransform: 'none'
                          }}
                        >
                          View
                        </Button>
                      </Box>
                    </Box>}
                    <Box
                      display='flex'
                      alignItems='center'
                      height='60px'
                      ml={1.5}
                      mt={2}
                      sx={{ mb: { xs: 1, sm: '0' } }}
                    >
                      <Box width='50px'>
                        <Avatar alt='pic' src={profile_picture ?? '/static/images/placeholder_profile.png'} />
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        justifyContent='center'
                        flex={1}
                      >
                        <Typography
                          variant='h6'
                          component='div'
                          color='black'
                          className='post-main-title'
                          sx={{ color: AppColors.blackColor,
                            display: "-webkit-box",        
                            "-webkit-box-orient": "vertical",
                            overflow: "hidden",             
                            "text-overflow": "ellipsis",     
                            "-webkit-line-clamp": "2",        
                            "line-clamp": "2",  
                           }}
                        >
                          {name}
                        </Typography>
                        <Box flexDirection={'row'} display={'flex'} sx={{ width: "100%" }}>
                          <Typography

                            component='div'
                            color='primary'
                            fontSize='10px'
                            className='post-main-sub-title'
                            sx={{ color: AppColors.grayTwoColor, mt: 0.4, flexGrow: 1, mr: 0.4 }}
                          >
                            {occupation}
                          </Typography>
                          <Typography

                            component='div'
                            color='primary'
                            fontSize='10px'
                            className='post-main-sub-title'
                            sx={{ color: AppColors.grayTwoColor, mt: 0.4 }}
                          >
                            {moment(community_feed?.company_feed?.created_at).format('DD MMMM YYYY')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {userInfo?.buy_now_link && userInfo?.buy_now_link.length>0 && (<Box sx={{ display: { md: 'flex', xs: 'none' }, width: "100%" }} flexDirection={'row'} alignItems='center'
                    justifyContent='space-between'>
                    {getTakeTheFastTrack()}
                  </Box>)}

                    {/*   Commentout social comment share like  */}
                    <Box sx={{ display: { md: 'flex', xs: 'none' }, width: "100%" }} flexDirection={'row'} alignItems='center'
                    >
                      {getLikeCommentShareView()}
                    </Box>

                  </Stack>

                  <div
                    ref={ref}
                    // className={'scroll'}
                    style={{
                      maxHeight: window?.innerWidth < 500 ? '400px' : '300px',
                    }}
                  >

                    {hasMedia && <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{ display: { xs: 'block', md: 'none' }, position: 'relative' }}
                    >
                      {community_feed?.company_feed?.contents != undefined &&
                        community_feed?.company_feed?.contents?.length > 0 && (
                          <Splide
                            aria-label='Images'
                            options={{
                              perPage: 1,
                              //fixedHeight: window?.innerWidth > 500 ? 390 : undefined,
                              heightRatio: 1,
                              //cover: true,
                              height: 'auto',
                            }}
                            onMove={(event) => {
                              setActiveSlide(event.index);
                            }}
                            onReady={(event) => {
                              setTotalSlide(event.length)
                            }}
                            style={{ borderRadius: "12px", overflow: 'hidden' }}
                          >
                            {community_feed?.company_feed?.contents?.map(
                              (content) => {
                                return content?.type === 'image' ? (
                                  <SplideSlide key={content.id}>
                                    <img
                                      src={content?.link}
                                      alt={content?.filename ?? ''}
                                      height='100%'
                                      width='100%'
                                      className='object-fit-contain'
                                    />
                                  </SplideSlide>
                                ) : (
                                  <SplideSlide key={content.id}>
                                    {content?.platform === 'youtube' ? (
                                      <YouTube
                                        videoId={content?.link}
                                        opts={opts}
                                        className='object-fit-contain'
                                        style={{
                                          height: '100%',
                                          width: '100%',
                                        }}
                                      // onPlay={() => handleOnplayVideo(content.id)}
                                      />
                                    ) : content?.platform === "vimeo" ? (
                                      <Vimeo
                                        video={content?.link}
                                        className="object-fit-contain vimeo-video-contain"
                                        height={"100%"}
                                        width={"100%"}
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          maxHeight: '50vh'
                                        }}
                                      // onPlay={() => handleOnplayVideo(content.id)}
                                      />
                                    ) : (
                                      <video
                                        controls
                                        width='100%'
                                        height='100%'
                                        className='object-fit-contain'
                                        poster={content?.thumbnail ?? ''}
                                      //onPlay={() => handleOnplayVideo(content.id)}
                                      >
                                        <source
                                          src={content?.link}
                                          type='video/mp4'
                                        />
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    )}
                                  </SplideSlide>
                                );
                              }
                            )}
                          </Splide>
                        )}
                      {community_feed?.company_feed?.contents && community_feed?.company_feed?.contents?.length > 1 && <Box sx={{ display: 'flex', flexDirection: 'row-reverse', position: 'absolute', top: 8, right: 10 }}>
                        <Button
                          variant='contained'
                          sx={{
                            backgroundColor: AppColors.primaryColor,
                            textTransform: 'none',
                            fontSize: "0.9rem",
                            zIndex: 1000,
                            lineHeight: 1,
                            fontWeight: "400",
                            py:0.5 
                          }}
                        >

                          {`${activeSlide + 1}`} of {totalSlide ?? 0}
                        </Button>
                      </Box>}
                    </Grid>}

                {userInfo?.buy_now_link && userInfo?.buy_now_link.length>0 && (<Box sx={{ display: { xs: 'flex', md: 'none' }, mt: 1.5, mb: 1.5, width: '100%' }} flexDirection={'row'} alignItems='center'
                  justifyContent='space-between'>
                  {getTakeTheFastTrack()}
                </Box>)}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, width: "100%", mb: 1 }} flexDirection={'row'} alignItems='center'
                    >
                      {getLikeCommentShareView()}
                    </Box>

                    {/* <Grid item xs={12} md={6}></Grid> */}
                    <Box
                      alignItems='flex-start'
                      justifyContent='flex-start'
                      alignContent='center'
                      sx={{ ml: { xs: 0, md: 1 }, mr: { xs: 0, md: 1 } }}
                      mb={1}
                      mt={1}
                    >
                      <Typography
                        component='div'
                        style={{
                          fontWeight: '500',
                          fontSize: 'medium',
                          color: AppColors.blackColor,
                        }}
                      >
                        {community_feed?.company_feed.title}
                      </Typography>
                      <Typography
                        variant='body2'
                        className='link'
                        sx={{ whiteSpace: 'pre-line', color: AppColors.grayTwoColor }}
                      >
                        <ReactReadMoreReadLess
                          charLimit={350}
                          readMoreText={'Read more'}
                          readLessText={'Read less'}
                          readMoreClassName='read-more-less-more'
                          readLessClassName='read-more-less-less'
                        >
                          {community_feed?.company_feed?.description ?? ''}
                        </ReactReadMoreReadLess>
                      </Typography>
                      {showMore && (
                        <Box
                          m={1}
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          <Button
                            variant='contained'
                            sx={{
                              backgroundColor: AppColors.primaryColor,
                              textTransform: 'none',
                              fontWeight: '500',
                              position: 'absolute',
                              bottom: 10,
                              zIndex: 1000,
                              lineHeight: 'normal',
                            }}
                            onClick={() => {
                              bottomRef?.current?.scrollIntoView({
                                behavior: 'smooth',
                              });
                              // setShowMore(!showMore);
                            }}
                          >
                            {/* {showMore ? 'Less' : 'More'} */}
                            More
                          </Button>
                        </Box>
                      )}
                    </Box>
                    <div ref={bottomRef}></div>
                  </div>
                </Grid>
              </Grid>
              {/* <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                mt={3}
              >
                <img
                  src='/static/images/knocard_small_logo.png'
                  alt='logo'
                  width={window?.innerWidth > 500 ? '15%' : '30%'}
                />
                <Typography
                  style={{
                    marginLeft: '10px',
                    fontWeight: '300',
                    fontSize: '1.8rem',
                  }}
                >
                  Social
                </Typography>
              </Box>
              <div style={{ background: AppColors.primaryColor, position: 'relative' }}>
                <div style={{ position: 'absolute', left: '-20px', zIndex: 1 }}>
                  <img
                    src='/static/images/kids-layers.png'
                    alt='logo'
                    height={window?.innerWidth > 500 ? '50%' : '35%'}
                    width={window?.innerWidth > 500 ? '50%' : '35%'}
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    left: '-4px',
                    top: '80px',
                    zIndex: 1,
                  }}
                >
                  <img
                    src='/static/images/politcs-layers.png'
                    alt='logo'
                    height={window?.innerWidth > 500 ? '50%' : '35%'}
                    width={window?.innerWidth > 500 ? '50%' : '35%'}
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    left: '-6px',
                    top: '174px',
                    zIndex: 1,
                  }}
                >
                  <img
                    src='/static/images/chaos-layers.png'
                    alt='logo'
                    height={window?.innerWidth > 500 ? '50%' : '35%'}
                    width={window?.innerWidth > 500 ? '50%' : '35%'}
                  />
                </div>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '8px' }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      flex={1}
                      style={{ zIndex: 11 }}
                    >
                      <Typography
                        variant='h6'
                        component='div'
                        color='white'
                        style={{ fontSize: '1.3rem', fontWeight: '700' }}
                      >
                        Want to
                      </Typography>
                      <Grid
                        container
                        spacing={4}
                        direction='row'
                        justifyContent='center'
                        sx={{ marginTop: '3px', zIndex: 99 }}
                      >
                        <Grid style={{ paddingTop: '0px' }}>
                          <Typography
                            variant='h6'
                            style={{
                              color: '#ffdc66',
                              fontSize: '1.6rem',
                              fontWeight: '900',
                              marginLeft: '25px',
                              textAlign: 'center'
                            }}
                          >
                            LIKE, FOLLOW, SHARE
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '8px' }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ zIndex: 11 }}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <Typography
                        color='white'
                        style={{
                          fontSize: '1.8rem',
                          fontWeight: '900',
                        }}
                      >
                        Join for FREE
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <a
                        href={config.APP_STORE_URL}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <img
                          src='/static/images/app_store_image.png'
                          height={window?.innerWidth > 500 ? '50%' : '70%'}
                          width={window?.innerWidth > 500 ? '50%' : '70%'}
                          alt='apple-link'
                        />
                      </a>
                      <a
                        href={config.PLAY_STORE_URL}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          textDecoration: 'none',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <img
                          src='/static/images/google_playstore_image.png'
                          height={window?.innerWidth > 500 ? '50%' : '70%'}
                          width={window?.innerWidth > 500 ? '50%' : '70%'}
                          alt='google-link'
                        />
                      </a>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '8px' }}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <FaRegComment
                        style={{
                          height: '1.5rem',
                          width: '1.5rem',
                          color: AppColors.primaryColor,
                        }}
                      />
                      <Typography
                        style={{
                          color: '#ffdc66',
                          fontSize: '1.1rem',
                          fontWeight: '600',
                        }}
                      >
                        Existing member?
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      sx={{ marginBottom: '25px' }}
                    >
                      <a
                        href={getOSLink()}
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'none' }}
                      >
                        <img
                          src='/static/images/sign-in-button.png'
                          height={window?.innerWidth > 500 ? '20px' : '20px'}
                          width={window?.innerWidth > 500 ? '120px' : '100px'}
                          alt='sign-up'
                        />
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </div> */}
            </>
          )}
        </DialogContent>
        <SocialDailog
          onClose={onCloseSocialDailog}
          userName={userName ?? ''}
          communityPostId={community_feed?.company_feed?.id ?? 0}
          toggle={toggleSocialDailog}
          deepLink={
            referenceUserAffiliateLink && referenceUserAffiliateLink.length > 0
              ? referenceUserAffiliateLink
              : community_feed?.company_feed?.deep_link
          }
        />

        {community_feed?.company_feed && <CompanyFeedCommentsModal
          toggle={toggleFeedComment}
          onClose={onCloseFeedComment}
          companyFeed={community_feed.company_feed} />}

        {community_feed?.company_feed && <CompanyFeedShareLinkModal
          toggle={toggleFeedLinkShare}
          onClose={onCloseFeedLinkShare}
          companyFeed={community_feed?.company_feed} />}
      </Dialog>
    </>
  );
};

export default ViewCommunityModal;
