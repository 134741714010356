import React, { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import { Box, Card, CardActionArea, CardContent, Grid, Stack, useMediaQuery } from "@mui/material";

import { config } from "../../utils/utils";
import styled from "@emotion/styled";
import styles from "../../styles/AffiliateLanding.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../store";
import Loader from '../../components/Loader';
import { getNetworkLandingPageVideos } from "../../store/networkLandingPageVideo/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import YoutubeVideoTutorialModel from "../../components/modal/YoutubeVideoTutorialModel";
import useToggle from "../../hooks/useToggle";
import { NetworkLandingPageVideoItem } from "../../store/networkLandingPageVideo/api/interface";
import { getUserInfo } from "../../store/userInfo/api";
import { UserInfo } from "../../store/userInfo/api/interface";
import useWindowSize from "../../utils/useWindowSize";
import { analytics } from "../../store/shareRefer/api";
import ContactFormModal from '../../components/ContactFormModal';
import { setUserInfo } from "../../store/userInfo/userInfoSlice";
import { ContactFormSourceType } from "../../enum";
import { checkIsProProOrProOneOrProPlusUser } from "../../utils/checkPro";
import GroupNetworkModal from "../../components/GroupNetworkModal";
import { LandingPageNewNavBar } from "../../components/LandingPageNewNavBar";
import { userInfo } from "os";
import NetworkLandingPageConfirmationModal from "../../components/NetworkLandingPageConfirmationModal";
import YouTube from 'react-youtube';
import zIndex from "@mui/material/styles/zIndex";
import { findAffiliateGroup } from "../../store/AffiliateGroup/api";
import { setItem } from "../../utils/localStorage";

const OpenButton = styled(Button)<ButtonProps>(({ theme }) => ({
  // backgroundColor: "#00cc00",
  backgroundImage: 'linear-gradient(270deg, #70F837 0%,   #1CB85D 100%)',
  color: "#000000",
  borderRadius: "30px",
  fontSize: "small",
  fontWeight: "bold",
  "&:hover": {
    background: "white",
    color: "#00cc00",
  },
  minWidth: "122px"
}));

const youtubeOpts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // Hide related videos after the video finishes
    rel: 0,
    // Hide the video annotations
    iv_load_policy: 3,
    // Hide the YouTube logo
    modestbranding: 1,
  },
};

const LimitlessLandingPage = () => {
  const toolbarMatches = useMediaQuery("(max-width:745px)");
  const navigate = useNavigate();
  const location = useLocation();
  const authUserInfo = useAppSelector((state) => state?.auth?.user);
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useAppSelector((state) => state.tutorialVideo.isLoading);


  const [searchParams] = useSearchParams();
  const [referredByLink, setReferredByLink] = useState('');
  const [referrUserInfo, setReferrUserInfo] = useState<UserInfo | undefined>(undefined);

  const [calendarLink, setCalendarLink] = useState('https://calendly.com/knocard/connect-with-me');


  const windowSize = useWindowSize();
  const [tabMinWidth, setTabMinWidth] = useState(90);
  const matches = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const newWidth = matches ? windowSize.width / 5 : 90;
    setTabMinWidth(newWidth);
  }, [windowSize]);


  const videoList = [
    {
      videoId: "61xJ_j_BiXQ",
      imageUrl: "/static/limitless_landing_page/reality_show_royalties.jpg",
      title: "Reality Show Royalties",
      description: "Founders experience royalties and rewards in the FIRST global reality show centered around enhancing children's lives with Blockchain Sports X Limitless."
    },
    {
      videoId: "_YjDDwHeJuc",
      imageUrl: "/static/limitless_landing_page/rewards_plan.webp",
      title: "Rewards Plan",
      description: "This 8 minute video details the exciting opportunity you have to start earning money quickly with Blockchain Sports Technology. "
    },
    {
      videoId: "x4M5tDtyVrQ",
      imageUrl: "/static/limitless_landing_page/hour_presentation.webp",
      title: "1 Hour Presentation",
      description: "Blockchain Sports Live Presentation: Join in on our global Limitless presentation and get your questions answered."
    },
    {
      videoId: "fx0sWPt0kFI",
      imageUrl: "/static/limitless_landing_page/interview_with_eric_neptune.webp",
      title: "Interview with Eric Nepute",
      description: "Dr. Eric Nepute reveals mind blowing information about sports science and health technology. This science is a literal game changer in the sports arena! "
    }
  ];


  const {
    toggle: toggleContactForm,
    onOpen: onOpenContactForm,
    onClose: onCloseContactForm,
  } = useToggle();

  const {
    toggle: toggleNetworkLandingPage,
    onOpen: onOpenNetworkLandingPage,
    onClose: onCloseNetworkLandingPage,
  } = useToggle();

  const {
    toggle: toggleGroup,
    onOpen: onOpenGroup,
    onClose: onCloseGroup,
  } = useToggle();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContactFormSubmitSuccess = (contactCode: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(config.PROSPECT_KEY);
    queryParams.set('sc', contactCode);
    navigate(`/network-landing-page?${queryParams.toString()}`);
  };

  const handleGetFoundersPack = () => {
    if (referrUserInfo?.buy_now_link) {
      window.open(`${referrUserInfo?.buy_now_link}`) || window.location.replace(`${referrUserInfo?.buy_now_link}`);
    } else {
      handleUserProfile();
    }
  }

  const handleWhitePaperVerification = () => {
    var whitePaperVerificationLink = "https://blockchain-sports.gitbook.io/whitepaper";
    window.open(`${whitePaperVerificationLink}`) || window.location.replace(`${whitePaperVerificationLink}`);
  }

  useEffect(() => {
    let refferLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      refferLink += `/affiliate/${userName}`;
    } else {
      refferLink += "/register";
    }
    setReferredByLink(refferLink);

    if (!isLoading) {
      dispatch(getNetworkLandingPageVideos());
    }

    if (userName.length > 0) {
      dispatch(getUserInfo({ username: userName })).unwrap().then((res) => {
        if (res.data.data.user.length > 0) {
          setReferrUserInfo(res.data.data.user[0]);
          dispatch(setUserInfo(res.data));
        }
      });
    }
    dispatch(findAffiliateGroup()).unwrap().then((result) => {
      if (result && result.status == 200 && result.data.data) {
        result.data.data.forEach((element: any) => {
          if (element.code === "tiffanylimitless") {

            setItem(config.GROUP_NETWORK_ID_KEY, element?.id);
          }
        });
      }
    });


  }, []);


  useEffect(() => {
    if (referrUserInfo) {
      if (searchParams.has(config.PROSPECT_KEY) && searchParams.get(config.PROSPECT_KEY) && checkIsProProOrProOneOrProPlusUser(referrUserInfo)) {
        onOpenContactForm();
      }
      saveLadingPageViewActivity();
    }
  }, [referrUserInfo]);


  const saveLadingPageViewActivity = () => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let elid = searchParams.get('elid');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/activity/save',
          data: {
            actionType: 'view',
            sourceType: 'link_share',
            module: 'group_landing_page',
            targetId: referrUserInfo?.id,
            identifiableId: referrUserInfo?.id,
            ...(loginUserId && { viewableId: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
            ...((!loginUserId && elid) && { elid: elid })
          },
        })
      );
    }
  }

  useEffect(() => {

    if (referrUserInfo) {
      if ((referrUserInfo.calendar_link ?? "").trim().length > 0) {
        setCalendarLink(referrUserInfo.calendar_link ?? "");
      } else {
        setCalendarLink(`tel:${referrUserInfo.phone_country_code ?? ""}${referrUserInfo.phone_number}`);
      }


    }

  }, [referrUserInfo])


  const getKnoCardSubscriptionLink = () => {
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`

    return subscriptionUrl;
  }


  const handleUserProfile = () => {
    let userProfileLink = `${config.VBC_URL}`;
    let userName = "";

    if (searchParams.has(config.REFER_KEY)) {
      userName = searchParams.get(config.REFER_KEY) ?? '';
      userProfileLink += `/${userName}`;
    } else {
      userProfileLink += "/register";
    }

    window.open(`${userProfileLink}`) || window.location.replace(`${userProfileLink}`);
  }

  const [openYoutubeVideo, setOpenYoutubeVideo] = useState<string | undefined>(undefined);

  const {
    toggle: togglenTutorialVideo,
    onOpen: onOpenTutorialVideo,
    onClose: onCloseTutorialVideo,
  } = useToggle();


  useEffect(() => {
    if (openYoutubeVideo != undefined) {
      onOpenTutorialVideo();
    }
  }, [openYoutubeVideo]);

  useEffect(() => {
    if (!togglenTutorialVideo) {
      setOpenYoutubeVideo(undefined);
    }
  }, [togglenTutorialVideo]);


  const handleOpenPricing = () => {
    const subscriptionUrl = `${config.VBC_URL}/subscription/${referrUserInfo?.username || ""}`
    window.open(`${subscriptionUrl}`) || window.location.replace(`${subscriptionUrl}`);
  }





  const handleOnVideoPlayActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "limitless-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };


  const handleOnPauseOrCompleteVideoActivity = (videoTitle: string, second: number, duration: number) => {
    if (referrUserInfo) {
      const sc = searchParams.get('sc');
      let loginUserId = authUserInfo?.id;

      dispatch(
        analytics({
          route: '/landing-page/video-watch-time',
          data: {
            target_id: referrUserInfo?.id ?? 0,
            module: "group_landing_page",
            title: videoTitle,
            target_page: "limitless-landing-page",
            video_watch_seconds: second,
            video_duration_seconds: duration,
            ...(loginUserId && { viewable_id: loginUserId }),
            ...((!loginUserId && sc) && { viewer_code: sc }),
          },
        })
      );
    }
  };

  const handleOnVideoPlay = (videoTitle: string, time: number, duration: number) => {

    if (time == 0) {
      handleOnVideoPlayActivity(videoTitle, time, duration);
    } else {
      handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
    }
  }

  const handleOnYoutubeVideoPlay = (videoId: string, time: number, duration: number) => {

    var videoTitle = "";

    videoList.forEach((item) => {
      if (item.videoId == videoId) {
        videoTitle = item.title;
      }
    });
    if (videoTitle.length > 0) {
      if (time == 0) {
        handleOnVideoPlayActivity(videoTitle, time, duration);
      } else {
        handleOnPauseOrCompleteVideoActivity(videoTitle, time, duration);
      }
    }
  }

  if (isLoading) return <Loader />;

  return (
    <React.Fragment>
      <LandingPageNewNavBar userInfo={referrUserInfo} affiliateUserName={referrUserInfo?.username} getKnoCardLink={getKnoCardSubscriptionLink()} />



      {/* SECTION 1  */}
      <section className={styles.section_1}>
        <Box

          sx={{ pb: 2, width: "100%", background: 'linear-gradient(180deg,#08349D 0%,#B74EDD 100%)', backgroundPosition: "bottom", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
        // sx={{
        //   minHeight: { xs: 'calc(100vh - 77px)', md: "unset" }
        // }}
        // flexDirection={'column'}
        // display='flex'
        // justifyContent='center'
        >
          <Stack flexDirection={"column"} sx={{
            pt: 2,
            pb: 1, alignContent: { xs: "flex-start", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FEFF09",
              width: "fit-content",
              mr: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopLeftRadius: { xs: "0px", sm: "10px" },
              borderBottomLeftRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.2rem',
                  color: '#000000',
                  textAlign: 'center',
                  pl: 4,
                  pr: 4,
                  pt: 1,
                  lineHeight: 1,
                  pb: 1,
                  borderRadius: "10px",
                  fontWeight: "bold",
                  '& span': {
                    fontSize: '1.5rem',
                  }
                }}
              >
                <span>A</span>TTN <span>W</span>ORLD <span>C</span>HANGERS

              </Typography>
            </Box>

          </Stack>
          <Typography
            component='p'
            sx={{
              fontSize: '1.6rem',
              color: '#ffffff',
              textAlign: 'center',
              pl: 1,
              pr: 1,
              pt: 2,
              lineHeight: 1.3,
              pb: 0.5,
              borderRadius: "10px",

            }}
          >
            Act quickly to get a piece of the action in AI Blockchain technology! We are running..

          </Typography>
          <Stack flexDirection={"column"} sx={{
            mb: 2, pt: 2,
            pb: 1, alignContent: { xs: "flex-end", sm: "center" }, alignItems: { xs: "start", sm: "center" }
          }}>
            <Box sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              background: 'linear-gradient(270deg,#0E47A2 0%,#4ED9DD 100%)',
              width: "fit-content",
              ml: 2,
              pb: "10px",
              pt: "10px",
              borderRadius: "10px",
              borderTopRightRadius: { xs: "0px", sm: "10px" },
              borderBottomRightRadius: { xs: "0px", sm: "10px" }
            }}>
              <Typography
                component='p'
                sx={{
                  fontSize: '1.5rem',
                  color: '#ffffff',
                  textAlign: 'center',
                  pl: 1,
                  pr: 1,
                  pt: 0.5,
                  lineHeight: 1,
                  pb: 0.5,
                  borderRadius: "10px"
                }}
              >
                Time is of the essence, don’t delay! Watch this quick overview

              </Typography>
            </Box>

          </Stack>

          <Stack flexDirection={"row"} justifyContent={'center'} sx={{
            mt: 2, mb: 2,
            ml: 1, mr: 1
          }}>
            <Box sx={{
              textAlign: 'center',
              border: "solid 4px #E4BEF2",
              borderRadius: "15px",
              width: { xs: '90%', md: '60%' },
              height: { xs: 'auto', md: '400px' },
            }}>
              <Box
                sx={{
                  p: { xs: 1, md: 1.5 },
                  maxWidth: '100%',
                  height: { xs: 'auto', md: '392px' },
                }}
              >
                <YouTube
                  videoId={"qqftn8O6Hic"}
                  opts={{ youtubeOpts }}
                  className='object-fit-contain'
                  style={{
                    height: '100%',
                    width: '100%'
                  }}
                  iframeClassName="full-height-width"

                  onPlay={(props: any) => {
                    if (props.target.getCurrentTime() < 1) {
                      handleOnVideoPlay("Limitless x Blockchain Sports Intro", 0, props.target.getDuration() || 0);
                    }
                  }}
                  onPause={(props: any) => {
                    handleOnVideoPlay("Limitless x Blockchain Sports Intro", props.target.getCurrentTime(), props.target.getDuration() || 0);
                  }}
                  onEnd={(props: any) => handleOnVideoPlay("Limitless x Blockchain Sports Intro", props.target.getCurrentTime(), props.target.getDuration() || 0)}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(270deg,#0E47A2 0%,#4ED9DD 100%)',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
          }}
        >
          <Box
            component={'img'}
            sx={{ width: '100%', maxWidth: "200px", position: "absolute", top: "30%", opacity: 0.1 }}
            src='/static/Affiliate_landing/dots_left.png'
            alt=''
          />
          <Typography
            component='p'

            sx={{
              zIndex: 1,
              fontSize: '1.5rem',
              color: '#ffffff',
              textAlign: 'center',
              mb: 2,
              p: 2,
              width: '100%',
              borderRadius: "10px",

            }}
          >
            Ground Breaking project poised to revolutionize the sports industry with the integration of technology
          </Typography>
          <Box sx={{
            zIndex: 1,
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'

              sx={{
                fontSize: '1.3rem',
                color: '#00000',
                backgroundColor: "#FEFF09",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",

              }}
            >
              Learn how it all works, and more importantly how it can transform your life
            </Typography>
          </Box>
        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to top,#000000 0%, #171717 100%)',
            backgroundSize: "cover",
            pt: 5,
            pb: 2,
            position: 'relative',
          }}
        >

          <Grid
            container
            spacing={2}

            justifyContent={"center"}
            sx={{
              position: "relative",
              textAlign: "center",
              pl: 2,
              pr: 2,
              overflow: "hidden"
            }}
          >
            <Box component={"img"} src={"/static/images/number_bg.png"} sx={{ opacity: 0.06, position: "absolute" }}></Box>

            {videoList && videoList.map((item, index) => {
              return (<Grid item sx={{ zIndex: 1 }} >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-line-clamp': '1',
                    '-webkit-box-orient': 'vertical',
                    color: '#FFFF0B',
                    fontSize: "large",
                    fontWeight: "bold",
                    mb: 1
                  }}
                >
                  {item.title}
                </Typography>
                <Card
                  sx={{
                    maxWidth: 345,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}

                >

                  <Box component={'div'} sx={{
                    p: 2,
                    border: "2px solid #61808F",
                    borderRadius: "15px",
                    backgroundColor: "#042C42"
                  }}>

                    <Box
                      component='div'
                      height='130px'
                      sx={{
                        background: `url(${item.imageUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        mt: 2,
                        borderRadius: "15px"
                      }}
                      onClick={() => {
                        setOpenYoutubeVideo(item.videoId);
                      }}
                    />

                    <CardActionArea
                      onClick={() => {
                        setOpenYoutubeVideo(item.videoId);
                      }}
                    >
                      <CardContent
                        sx={{
                          p: 0.5,
                          '&:last-child': { p: 1 },
                          minHeight: '36px',
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box>

                          <Typography
                            variant='body2'
                            color='text.secondary'
                            sx={{
                              pt: 1.5,
                              fontSize: "medium",
                              overflow: 'hidden',
                              display: '-webkit-box',
                              // '-webkit-line-clamp': '3',
                              // '-webkit-box-orient': 'vertical',
                              color: '#ffffff',

                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>);
            })}



          </Grid>
          <Box sx={{ textAlign: 'center', mt: 4, pb: 2 }}>
            <OpenButton
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                width: 'fit-content',
                textTransform: 'unset',
                fontWeight: "bold",

              }}
              onClick={handleGetFoundersPack}
            >
              Get Founder's Pack
            </OpenButton>
          </Box>

        </Box>
      </section>

      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(270deg,#B950DE 0%,#19B2D7 50%,#B950DE 100%)',
            backgroundSize: "cover",
            pt: 2,
            pb: 2,
            position: 'relative',
          }}
        >


          <Box sx={{ textAlign: 'center', mt: 2, pb: 2 }}>
            <Button
              sx={{
                backgroundImage: 'url("/static/limitless_landing_page/white_paper_verification.webp")',
                backgroundSize: "cover",
                color: "#000000",
                borderRadius: "10px",
                fontSize: "medium",
                fontWeight: "bold",
                minWidth: "122px",
                pl: 4,
                pr: 4,
                pt: 1.5,
                pb: 1.5,
                width: 'fit-content',
                textTransform: 'unset'
              }}
              onClick={handleWhitePaperVerification}
            >
              White Paper Verification
            </Button>
          </Box>


          <Box sx={{
            zIndex: 1,
            textAlign: "center", width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pr: 2
          }}>
            <Box component={"img"} src={"/static/rejection_free/white_line.png"}></Box>
            <Typography
              component='p'

              sx={{
                fontSize: '1.3rem',
                color: '#00000',
                backgroundColor: "#FEFF09",
                textAlign: 'center',
                mt: 1,
                mb: 2,
                p: 2,
                width: 'fit-content',
                borderRadius: "10px",

              }}
            >
              Learn how it all works, and more importantly how it can transform your life
            </Typography>
          </Box>
        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: '#171717',

            pb: 2,
            textAlign: "center"
          }}
        >

          <Typography
            component='p'
            sx={{
              fontSize: "1.5rem",
              fontFamily: "'Lato', sans-serif",
              color: '#FDFF0B',
              textAlign: { xs: 'start', md: 'center' },
              fontWeight: "bold",
              mb: 1,
              p: 1,
              ml: 2,
              pt: 2
            }}
          >
            The System
          </Typography>

          <Box sx={{ justifyContent: "center", display: "flex" }}>
            <Box sx={{
              border: "solid 2px #658491",
              borderRadius: "10px",
              backgroundColor: "#022D42",
              width: "fit-content",
              ml: 2,
              mr: 2
            }}>
              <Box
                component={'video'}
                autoPlay={false}
                id='intro_video'
                controls
                poster={"https://storage-prod.knocard.net/rejection_free_page_video/thumbnail/1712703156_1707358330.jpg"}
                sx={{
                  pt: { xs: 1, md: 3 },
                  pl: { xs: 1, md: 5 },
                  pr: { xs: 1, md: 5 },
                  mb: 2,
                  maxWidth: '100%',
                  height: { xs: 'auto', md: '400px' },
                }}
                onPlay={(props: any) => {
                  if (props.currentTarget.currentTime == 0) {
                    handleOnVideoPlay("The System", 0, props.currentTarget.duration || 0);
                  }
                }}
                onPause={(props: any) => {
                  handleOnVideoPlay("The System", props.currentTarget.currentTime, props.currentTarget.duration || 0);
                }}
              >
                <source src={"https://storage-prod.knocard.net/rejection_free_page_video/1712703158_2067936189.mp4"} type='video/mp4' />
              </Box>
            </Box>
          </Box>
          <Grid container>
            {/* <Grid item xs={6}>
              <Typography
                component='p'
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "'Lato', sans-serif",
                  color: '#FDFF0B',
                  textAlign: 'center',
                  fontWeight: "bold",
                  mb: 1,
                  p: 1
                }}
              >
                Starts as low as
              </Typography>
              <Stack flexDirection={"row"} justifyContent={'center'}>
                <Box sx={{
                  background: 'linear-gradient(142deg,#F8CE4B 0%,#E9E5B9 100%)',
                  p: { xs: 2, sm: 3 },
                  mr: 0.5,
                  borderRadius: "100%"
                }}>
                  <Typography
                    sx={{
                      color: "#1275BB",
                      fontWeight: "bold",
                      fontSize: "1.5rem"
                    }}>
                    $34
                  </Typography>
                </Box>
                <Box sx={{
                  background: 'linear-gradient(142deg,#F8CE4B 0%,#E9E5B9 100%)',
                  p: { xs: 2, sm: 3 },
                  ml: 0.5,
                  borderRadius: "100%"
                }}>
                  <Typography
                    sx={{
                      color: "#1275BB",
                      fontWeight: "bold",
                      fontSize: "1.5rem"
                    }}>
                    $69
                  </Typography>
                </Box>
              </Stack>
            </Grid> */}
            <Grid item xs={12}>
              <Typography
                component='p'
                sx={{
                  mt: 2,
                  fontSize: "1.3rem",
                  fontFamily: "'Lato', sans-serif",
                  color: '#ffffff',
                  textAlign: 'center',
                  fontWeight: "bold",
                  mb: 1,
                  p: 1
                }}
              >
                Check out our Promotions
              </Typography>

              <OpenButton
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                }}
                onClick={handleOpenPricing}
              >
                Pricing
              </OpenButton>
            </Grid>
          </Grid>

        </Box>
      </section>


      <section>
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(to top, #082A3E, #0B4265)',
            pt: 5,
            pb: 5,
            position: 'relative',
            overflow: "hidden"

          }}
        >
          <Box
            component='img'
            src={'/static/custom_landing_page/network.png'}
            sx={{ width: { sm: "50%", xs: "80%" }, position: "absolute", top: 0, right: "-10%", opacity: 0.1 }}
          />
          <Typography
            sx={{
              fontSize: '2.3rem',
              textAlign: 'center',
              fontFamily: "'Lato', sans-serif",
              fontWeight: 'bold',
              pl: 1,
              pr: 1,
              color: "#29D9F0"
            }}
          >
            So What's next?
          </Typography>

          <Box sx={{
            background: 'linear-gradient(270deg, #50DADE 0%, #104BA6 100%)',
            p: 1.5,
            mt: 2,
            mr: { sm: 5, xs: 2 },
            ml: { sm: 0, xs: 2 },
            borderRadius: "16px",
            borderTopLeftRadius: { sm: 0, xs: "16px" },
            borderBottomLeftRadius: { sm: 0, xs: "16px" }


          }}>
            <Typography sx={{ textAlign: "center" }}>
              Choose the Founder’s Pack that you want to START with. Follow directions on the attachments using my link to join today!
            </Typography>
          </Box>


          <Box sx={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", width: "calc(100% - 96px)", position: "absolute", top: 206, display: { xs: "none", md: "block" }, height: "140px", border: "2px solid #1677AE", ml: 12, borderRightWidth: "0px" }}></Box>
          <Stack
            flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ p: { sm: 2, xs: 2, md: 5 } }}


          >
            <Box
              component={'img'}
              src='/static/rejection_free/calendar.png'
              sx={{
                height: '100px',
                objectFit: 'contain',
                mr: { sm: 0, xs: 0, md: '-5px' },
                zIndex: 1,
                mt: { sm: 0, xs: 0, md: 3 },
                backgroundColor: { sm: "unset", md: "#074766" },
                borderRadius: "10px"
              }}
            />

            <Stack alignContent={"center"} alignItems={"center"} sx={{ mt: { sm: 0, xs: 0, md: 3 }, width: "100%", textAlign: "center" }} >
              <Typography
                sx={{
                  color: '#26DDE9',
                  fontWeight: "bold",
                  fontSize: '1.5rem',
                  pt: 1,
                  textAlign: 'center',
                }}
              >
                Questions? We've got answers..
              </Typography>
              <Button
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  width: 'fit-content',
                  textTransform: 'unset',
                  fontWeight: "bold",
                  backgroundImage: 'linear-gradient(0deg, #ffffff 0%, #FCC83A 100%)',
                  color: "#000000",
                  borderRadius: "30px",
                  fontSize: "small",
                  "&:hover": {
                    background: "#ffffff",
                  },
                  minWidth: "122px"
                }}
                onClick={onOpenNetworkLandingPage}
              >
                Let's Chat
              </Button>


            </Stack>


            {referrUserInfo && (
              <Box
                sx={{
                  textAlign: 'center',
                  mt: { md: 0.2, xs: 4 },
                  zIndex: 100
                }}
                flexDirection={'column'}
              >
                <Box
                  component={'img'}
                  src={
                    referrUserInfo?.profile_picture
                      ? referrUserInfo?.profile_picture
                      : '/static/images/placeholder_profile.png'
                  }
                  sx={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '75px',
                    border: '4px solid #228FBE',
                  }}
                />
                <Typography
                  sx={{
                    mt: 1,
                    fontWeight: 'bold',
                    color: '#ffffff',

                  }}
                >
                  {referrUserInfo ? referrUserInfo.name : ''}
                </Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    color: "#ffffff",

                  }}
                >
                  {referrUserInfo ? referrUserInfo.occupation : ''}
                </Typography>
              </Box>
            )}

          </Stack>
        </Box>
      </section>


      {
        openYoutubeVideo && (
          <YoutubeVideoTutorialModel
            key={`open-youtube-video-model-${openYoutubeVideo}`}
            onClose={onCloseTutorialVideo}
            toggle={togglenTutorialVideo}
            youtubeVideoId={openYoutubeVideo}
            onVideoPlay={handleOnYoutubeVideoPlay}
            onVideoPause={handleOnYoutubeVideoPlay}
          />
        )
      }

      <ContactFormModal
        onClose={onCloseContactForm}
        toggle={toggleContactForm}
        onSubmitSuccessRedirect={false}
        onSubmitSuccess={handleContactFormSubmitSuccess}
        source={ContactFormSourceType.WHAT_IS_KNOCARD}
      />

      <GroupNetworkModal
        onClose={onCloseGroup}
        toggle={toggleGroup}
        affiliateLink={referredByLink}
        userInfo={referrUserInfo}
      />

      <NetworkLandingPageConfirmationModal
        onClose={onCloseNetworkLandingPage}
        toggle={toggleNetworkLandingPage}
        link={calendarLink}
        userInfo={referrUserInfo}
      />
    </React.Fragment >
  );
};

export default LimitlessLandingPage;
